import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";

const ImprintPage = ({ data }) => {
  let html = data.allMarkdownRemark.nodes[0].html;
  return (
    <Layout>
      <div dangerouslySetInnerHTML={{ __html: html }} class="impress"></div>
    </Layout>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(imprint.md)/" } }
    ) {
      nodes {
        html
      }
    }
  }
`;

export default ImprintPage;
